<template>
    <div>
      <div class="alert alert-info">
        SQLs de integração são utilizados para sobrescrever as consultas padrões de extração de dados do ERP para dentro
        das nossas plataformas. Através deles podem ser implementados/ajustados regras mais específicas
        da sua operação. O nome da consulta leva o mesmo nome da view que se encontra no banco de dados para melhor
        localização e entendimento.
      </div>
        <crud-base
            :url="url"
            :columns="columns"
            :onDelete="() => {}"
            :onUpdate="() => {}"
            :onStore="() => {}"
            :enableSearch="true"
            :formInputs="formInputs"
            primaryKey="view_name"
        >
          <template v-slot:edit-buttons="{model}">
            <button type="button" v-on:click="simulateQuery(model)" class="btn btn-primary">
              Executar Sql
            </button>
          </template>
          <template v-slot:action-buttons="{model}">
            <router-link v-if="!!model.tarefa" :to="`/configuracoes/cargas?q=${model.view_name}`">
              Ver carga
            </router-link>
            &nbsp;
            <button type="button" v-on:click="simulateQuery(model)" class="button button-info" title="Simular query">
              <fa-icon icon="eye" />
            </button>
          </template>
        </crud-base>
        <ModalSimulateQuery
            :sql="currentSql"
        />
    </div>
</template>

<script>
  import CrudBase from "../../../components/crud/CrudBase";
  import ModalSimulateQuery from "@/views/SqlErpIntegracao/sqls/ModalSimulateQuery";

  export default {
    name: "SQLsErpIntegracao",
    components: {
      ModalSimulateQuery,
      CrudBase
    },
    data() {
      return {
        url: '/admin/sqls-erp',
        currentSql: null,
        columns: [
          {label: 'View', field: 'view_name', sortable: false},
          {label: 'Descrição', field: 'descricao', sortable: false},
          {label: 'Ativo', field: 'ativo', sortable: false, representedAs: (row) => row.ativo ? 'Sim' : 'Não'},
        ],
        formInputs: {
          view_name: {
            type: 'string',
            ui: {
              columns: 8,
              label: 'Nome da view',
            },
            rules: {
              required: {
                value: true,
                errMsg: "Campo de preenchimento obrigatório"
              },
            }
          },
          ativo: {
            type: 'boolean',
            default: 1,
            ui: {
              columns: 4,
              label: 'Ativo',
              "widgetConfig": {
                "enumSource": [
                  {
                    "label": "Sim",
                    "value": 1
                  },
                  {
                    "label": "Não",
                    "value": 0
                  }
                ]
              },
            }
          },
          descricao: {
            type: 'string',
            ui: {
              widget: "textarea",
              label: 'Descrição da view',
            },
          },
          sql: {
            type: 'string',
            ui: {
              widget: "codeblock",
              label: 'SQL da Consulta',
            },
          },
        }
      }
    },
    methods: {
      simulateQuery(model) {
        this.currentSql = model.sql;
        this.$modal.show('simulate-query');
      }
    },
  }
</script>